import { ActionCall, ActionOutput, ObjectApi } from '@grenton/gm-common';
import { BlockCreator } from '../visual/blockCreator';
import { blocks } from '@grenton/gm-logic';
import { ResolvedPath } from '@grenton/gm-logic';
import { schema } from '@grenton/gm-common';
import { Block } from 'blockly';

export type ActionCodeGenerator<Model> = (model: Model, path: ResolvedPath) => ActionOutput;

type CallableBlock = blocks.BlockWithDelegate<blocks.FeatureSetBlock.Delegate> | blocks.BlockWithDelegate<blocks.MethodInvokeBlock.Delegate>;

export function generateBlocksForCallables(calls: ActionCall[], path: ResolvedPath, creator: BlockCreator, api: ObjectApi): CallableBlock[] {
    const actionBlocks: CallableBlock[] = [];
    for (const call of calls) {
        // create target entity block (owner of the callable)
        const callableOwnerBlock = creator.addEntity(path.serialize());
        // callable is either a feature setter or a method
        const feature = api.flat.features[call.callable];
        if (feature) {
            const actionBlock = creator.newBlock(blocks.FeatureSetBlock.Type, (block: blocks.BlockWithDelegate<blocks.FeatureSetBlock.Delegate>) => {
                block.delegate.connectToObjectBlock(callableOwnerBlock, call.callable);

                if (call.params?.value !== undefined) {
                    const valueBlock = createValueBlock(creator, feature.type, call.params.value);
                    block.delegate.connectToValueBlock(valueBlock);
                }
            });
            actionBlocks.push(actionBlock);
        } else {
            const method = api.flat.methods[call.callable];
            if (method) {
                const actionBlock = creator.newBlock(blocks.MethodInvokeBlock.Type, (block: blocks.BlockWithDelegate<blocks.MethodInvokeBlock.Delegate>) => {
                    block.delegate.connectToObjectBlock(callableOwnerBlock, call.callable);
                    block.delegate.ensureArgsInputs(method.params?.length ?? 0, method.params);
                    method.params?.forEach((param, index) => {
                        const value = call.params?.[param.id];
                        if (value !== undefined) {
                            const valueBlock = createValueBlock(creator, param.type, value);
                            block.delegate.connectToArgumentBlock(index, valueBlock);
                        }
                    });
                });

                actionBlocks.push(actionBlock);
            } else {
                // unexpected
            }
        }
    }
    return actionBlocks;
}

function createValueBlock(creator: BlockCreator, type: schema.PropertyValueType, value: schema.PropertyValue): Block {
    if (type === 'null' || value === null) {
        return creator.newBlock('logic_null');
    }

    switch (type) {
        case 'string': {
            const valueBlock = creator.newBlock('text');
            valueBlock.setFieldValue(value, 'TEXT');
            return valueBlock;
        }
        case 'boolean': {
            const valueBlock = creator.newBlock('logic_boolean');
            valueBlock.setFieldValue(value ? 'TRUE' : 'FALSE', 'BOOL');
            return valueBlock;
        }
        case 'integer':
        case 'number': {
            const valueBlock = creator.newBlock('math_number');
            valueBlock.setFieldValue(value.toString(), 'NUM');
            return valueBlock;
        }
        case 'object': {
            // TODO. for object we need custom block to hold json (I think)
            const valueBlock = creator.newBlock('text');
            valueBlock.setFieldValue(value, 'TEXT');
            return valueBlock;
        }
    }
}
