import { Block, Blocks, FieldLabel, MenuOption, inputs } from 'blockly';
import { luaGenerator, Order } from 'blockly/lua';
import { _withProtocol, BlockContext } from '@grenton/gm-logic';
import {
    BlockDelegate,
    createDelegate,
    emptyOption,
    FieldDropdownModel,
    getSortedApis,
    NOT_SELECTED,
    SELECTION_OUTPUT_TYPES,
    VALUE_BLOCK_STYLE,
} from './common';
import { FieldDropdownEx } from './utils/field-dropdown-ex';

export namespace SelectionWithProtocolBlock {
    export const Type = 'g-selection-with-protocol';

    export const INPUT_SELECTION = 'objects';
    export const API_FIELD = 'api';

    const EMPTY_OPTION = emptyOption('{protocol}');

    export class Delegate implements BlockDelegate {
        inputIsSelection = false;

        dropdownModel: FieldDropdownModel;

        constructor(private block: Block) {
            this.dropdownModel = new FieldDropdownModel(block, API_FIELD, [EMPTY_OPTION]);

            block.setOutput(true, SELECTION_OUTPUT_TYPES);

            block.appendValueInput(INPUT_SELECTION).setAlign(inputs.Align.RIGHT).appendField(new FieldLabel('select'));

            block
                .appendDummyInput()
                .setAlign(inputs.Align.RIGHT)
                .appendField(new FieldLabel(_withProtocol))
                .appendField(new FieldDropdownEx(this.dropdownModel.generator), API_FIELD);

            block.setInputsInline(false);
            block.setStyle(VALUE_BLOCK_STYLE);
            block.setTooltip('');
            block.setHelpUrl('');
        }

        onUpdate(context: BlockContext) {
            const selectedApiName = this.block.getFieldValue(API_FIELD);
            const all = getSortedApis(context);
            const api = all.find((a) => a.id === selectedApiName);

            // to make it happen, we need to return ObjectApiImpl + reference, not entire entity
            if (api) {
                context.setReturnedType({ type: 'selection', api });
            }

            const options: MenuOption[] = all.map((api) => [api.id, api.id]);
            options.unshift(EMPTY_OPTION);
            this.dropdownModel.setOptions(options, selectedApiName);
        }
    }
}

Blocks[SelectionWithProtocolBlock.Type] = createDelegate((block) => new SelectionWithProtocolBlock.Delegate(block));

luaGenerator.forBlock[SelectionWithProtocolBlock.Type] = (block, generator) => {
    const input = generator.valueToCode(block, SelectionWithProtocolBlock.INPUT_SELECTION, Order.NONE) || NOT_SELECTED;
    const api = block.getFieldValue(SelectionWithProtocolBlock.API_FIELD) || NOT_SELECTED;
    const code = `${input.trim()}.${_withProtocol}("${api}")`;
    return [code, Order.ATOMIC];
};
