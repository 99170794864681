import { Project, uuid } from '@grenton/gm-common';
import { ProjectObjectImpl, ScriptImpl, ProjectRevisionsImpl, StateProvider, ProjectImpl, resolvePath } from '@grenton/gm-logic';
import { Workspace, serialization } from 'blockly';
import log from 'loglevel';
import { luaGenerator } from 'blockly/lua';
import { ScriptWithContext } from '@grenton/gm-logic';
import { generateCodeForActionMethodCalls } from '@grenton/gm-logic';
import { traverseBlockGraph, BlockContextImpl } from '@grenton/gm-logic';
import { ProjectValidationResult, ProjectValidationResultNone, ProjectValidator } from '../project/validator';

export type ProjectExportInfo = {
    type: 'logic';
    message: string;
};

export type ExportedProject = {
    validation: ProjectValidationResult;
    info: ProjectExportInfo[];
    project: Project;
};

export class ProjectExporter {
    constructor(
        private projectProvider: StateProvider<ProjectImpl | null>,
        private projectValidator: ProjectValidator,
    ) {}

    // here we should re-generate code for all scripts in visual mode.
    // the reason is that there are many outside parameters that may impact generated code,
    // like variable or method names, and doing this on any change would be costly
    // we can also apply code validation here

    // btw, this operation will be necessary also before running simulation etc.
    async exportProject(opts?: { newVersion?: boolean; validate?: boolean; optimize?: boolean }): Promise<ExportedProject> {
        let projectImpl = this.projectProvider.value;
        if (!projectImpl) throw new Error('project not loaded');

        // we probably don't want to automatically modify project here, even if all these changes make sense
        // this should happen only after successful upload (what about partially successful uploads?)
        //this.holder.update(_=>p)
        if (opts?.newVersion) {
            projectImpl = projectImpl.withNewRevision(ProjectRevisionsImpl.revision()).withHardware(
                projectImpl.hardware.withCluster({
                    id: uuid.v4(),
                    secret: uuid.v4(),
                }),
            );
        }

        let exportResult = {
            project: await this.exportProjectImpl(projectImpl),
            info: [] as ProjectExportInfo[],
        };

        // optimize by default
        if (opts?.optimize !== false) {
            exportResult = this.optimizeProject(exportResult.project);
        }

        return {
            ...exportResult,
            validation: opts?.validate ? this.projectValidator(exportResult.project) : ProjectValidationResultNone,
        };
    }

    private optimizeProject(project: Project): { project: Project; info: ProjectExportInfo[] } {
        const info: ProjectExportInfo[] = [];
        let p = project;
        // remove controllers with no scripts and no features
        // TODO leave controllers that extend some API (built-in features?)
        p = {
            ...p,
            objects: project.objects.filter((obj) => {
                if (obj.impl?.type !== 'script') return true;
                return (
                    Boolean(obj.init?.features?.length) ||
                    obj.scripts?.some((script) => script.script.steps && script.script.steps.some((step) => Boolean(step.code?.trim().length)))
                );
            }),
        };
        const removedControllers = project.objects.length - p.objects.length;
        //if (removedControllers) {
        info.push({ type: 'logic', message: `removed ${removedControllers} empty controllers` });
        //}

        return {
            project: p,
            info,
        };
    }

    private async exportProjectImpl(project: ProjectImpl): Promise<Project> {
        // TODO remove all UNUSED (empty) controllers. We create bunch of anonymous controllers,
        // but most of them won't be ever used!
        // CLU do not need to create them.

        // TODO integrate with editor
        function actionScriptUpdater(root: ProjectObjectImpl, script: ScriptImpl) {
            if (script.actions) {
                const code = script.actions.items.map((item) => {
                    const resolvedPath = resolvePath(item.target, project!.objectResolver, root.uuid);
                    return [
                        `-- action "${item.action.type}" on "${resolvedPath.head?.name}"`,
                        generateCodeForActionMethodCalls(item.output.calls, resolvedPath),
                        '\n',
                    ].join('\n');
                });
                return script.withCode(code.join('\n'));
            } else {
                return script;
            }
        }

        // TODO integrate with editor
        function visualScriptUpdater(editedScript: ScriptWithContext, script: ScriptImpl) {
            if (script.visual) {
                // update code
                const w = new Workspace();
                serialization.workspaces.load(JSON.parse(script.visual), w);
                traverseBlockGraph(w, new BlockContextImpl(project!, editedScript, {}));
                const code: string = (luaGenerator as any).workspaceToCode(w);

                log.debug('regenerated', code);
                return script.withCode(code);
            } else {
                return script;
            }
        }

        // function logicModifier(entity:ProjectObjectImpl) {
        //  return (logic:ProjectLogicBlockImpl)=>
        //     logic
        //         .withOutletsModifier(outlet=>
        //             outlet.withEventHandlersModifier(eventHandlers=>
        //                 eventHandlers.withHandlersModifier(handler=>{
        //                     return handler.withScript(scriptUpdater(new EditedScript(entity,{type:'event',outletId:outlet.id,eventName:handler.event}), handler.script))
        //         })))
        //         .withMethodModifier(method=>{
        //             return method.withScript(scriptUpdater(new EditedScript(entity,{type:'method',methodId:method.id}), method.script))
        //         })
        // }

        let p = project;
        Object.values(p.objects).forEach((obj) => {
            let updated = obj.scripts;
            Object.entries(updated.scripts).forEach(([path, script]) => {
                let updatedScript = script;
                switch (script.format) {
                    case 'actions':
                        {
                            updatedScript = actionScriptUpdater(obj, script);
                        }
                        break;
                    case 'visual':
                        {
                            const fullPath = [obj.uuid, ...path.split('.')];
                            const scriptContext = ScriptWithContext.fromPath(p, fullPath);
                            if (!scriptContext) throw new Error('script context not created for path ' + fullPath.join('.'));
                            updatedScript = visualScriptUpdater(scriptContext, script);
                        }
                        break;
                }
                updated = updated.withScript(path, updatedScript);
            });
            p = p.withObject(obj.withScripts(updated));
        });

        return p.export();
    }
}
