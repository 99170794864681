import { Block, Blocks, FieldLabel, MenuOption } from 'blockly';
import { luaGenerator } from 'blockly/lua';
import { Maps } from '@grenton/gm-common';
import { BlockContext } from '@grenton/gm-logic';
import { BlockDelegate, BlockWithDelegate, createDelegate, emptyOption, FieldDropdownModel, STATEMENT_BLOCK_STYLE } from './common';
import { FieldDropdownEx } from './utils/field-dropdown-ex';

export namespace EmitEventBlock {
    export const Type = 'g-emit-event';

    const EVENT_SELECTOR = 'EVENT_SELECTOR';
    const EMPTY_OPTION = emptyOption('{event}');

    export class Delegate implements BlockDelegate {
        eventName?: string;

        dropdownModel: FieldDropdownModel;

        constructor(private block: Block) {
            this.dropdownModel = new FieldDropdownModel(block, EVENT_SELECTOR, [EMPTY_OPTION]);

            this.block
                .appendDummyInput()
                .appendField(new FieldLabel('emit event'), 'LABEL')
                .appendField(new FieldDropdownEx(this.dropdownModel.generator), EVENT_SELECTOR);

            this.block.setPreviousStatement(true, null);
            this.block.setNextStatement(true, null);
            block.setStyle(STATEMENT_BLOCK_STYLE);
            this.block.setTooltip('emit event');
            this.block.setHelpUrl('');
        }

        onUpdate(context: BlockContext) {
            const id = this.dropdownModel.value;
            const editedEntity = context.editedScript.object;
            const event = editedEntity.api.events[id];

            this.eventName = event?.name;

            const options: MenuOption[] = Maps.values(editedEntity.api.api.flat.events).map((o) => [o.label || o.id, o.id]);

            // we need "unselected" option.
            // e.g. when controller is removed, we cannot keep the previous value...

            options.unshift(EMPTY_OPTION);
            this.dropdownModel.setOptions(options, event?.id || '');
        }
    }
}

Blocks[EmitEventBlock.Type] = createDelegate((block) => new EmitEventBlock.Delegate(block));

luaGenerator.forBlock[EmitEventBlock.Type] = (block) => {
    const _block = block as BlockWithDelegate<EmitEventBlock.Delegate>;
    const code = `self:emitEvent("${_block.delegate.eventName || '{not_selected}'}")\n`;
    return code;
};
