import { Block, Blocks, FieldLabel, MenuOption } from 'blockly';
import { Order, luaGenerator } from 'blockly/lua';
import {
    BlockDelegate,
    createDelegate,
    emptyOption,
    FieldDropdownModel,
    getSortedApis,
    NOT_SELECTED,
    SELECTION_OUTPUT_TYPES,
    VALUE_BLOCK_STYLE,
} from './common';
import { FieldDropdownEx } from './utils/field-dropdown-ex';
import { _withProtocol, BlockContext, objects } from '@grenton/gm-logic';

export namespace SelectionCreateBlock {
    export const Type = 'g-selection-create';

    export const API_FIELD = 'api';

    const EMPTY_OPTION = emptyOption('{protocol}');

    export class Delegate implements BlockDelegate {
        dropdownModel: FieldDropdownModel;

        constructor(private block: Block) {
            this.dropdownModel = new FieldDropdownModel(block, API_FIELD, [EMPTY_OPTION]);

            block
                .appendDummyInput()
                .appendField(new FieldLabel(`select all ${_withProtocol}`))
                .appendField(new FieldDropdownEx(this.dropdownModel.generator), API_FIELD);

            block.setOutput(true, SELECTION_OUTPUT_TYPES);

            block.setInputsInline(false);
            block.setStyle(VALUE_BLOCK_STYLE);
            block.setTooltip('');
            block.setHelpUrl('');
        }

        onUpdate(context: BlockContext) {
            const selectedApiName = this.block.getFieldValue(API_FIELD);
            const all = getSortedApis(context);
            const api = all.find((a) => a.id === selectedApiName);

            // to make it happen, we need to return ObjectApiImpl + reference, not entire entity
            if (api) {
                context.setReturnedType({ type: 'selection', api });
            }

            const options: MenuOption[] = all.map((api) => [api.id, api.id]);
            options.unshift(EMPTY_OPTION);
            this.dropdownModel.setOptions(options, selectedApiName);
        }
    }
}

Blocks[SelectionCreateBlock.Type] = createDelegate((block) => new SelectionCreateBlock.Delegate(block));

luaGenerator.forBlock[SelectionCreateBlock.Type] = (block) => {
    //const _block = block as BlockWithDelegate<ObjectSelectionAllBlock.Delegate>
    const api = block.getFieldValue(SelectionCreateBlock.API_FIELD) || NOT_SELECTED;
    const code = `${objects}.${_withProtocol}("${api}")`;
    return [code, Order.ATOMIC];
};
