import { Block, Blocks, FieldLabel } from 'blockly';
import { luaGenerator, Order } from 'blockly/lua';
import { _first, BlockContext } from '@grenton/gm-logic';
import { BlockDelegate, createDelegate, NOT_SELECTED, VALUE_BLOCK_STYLE } from './common';

export namespace SelectionFirstObjectBlock {
    export const Type = 'g-selection-first';

    export const OBJECT_SELECTION_INPUT = 'INPUT';

    export class Delegate implements BlockDelegate {
        outletName?: string;

        constructor(block: Block) {
            block.appendValueInput(OBJECT_SELECTION_INPUT).appendField(new FieldLabel(`select ${_first}`));
            block.setOutput(true);
            block.setInputsInline(false);
            block.setTooltip('');
            block.setHelpUrl('');
            block.setStyle(VALUE_BLOCK_STYLE);
        }

        onUpdate(context: BlockContext) {
            const entity = context.getInputType(OBJECT_SELECTION_INPUT);
            if (entity?.type === 'outlet' || entity?.type === 'selection') {
                context.setReturnedType(entity);
            }
        }
    }
}

Blocks[SelectionFirstObjectBlock.Type] = createDelegate((block) => new SelectionFirstObjectBlock.Delegate(block));

luaGenerator.forBlock[SelectionFirstObjectBlock.Type] = (block, generator) => {
    const entityRef = generator.valueToCode(block, SelectionFirstObjectBlock.OBJECT_SELECTION_INPUT, Order.ATOMIC) || NOT_SELECTED;
    const code = `${entityRef}.${_first}`;
    return [code, Order.ATOMIC];
};
