import { Block, Blocks, FieldLabel, inputs } from 'blockly';
import { Order, luaGenerator } from 'blockly/lua';
import { _withType, BlockContext } from '@grenton/gm-logic';
import { BlockDelegate, BlockWithDelegate, createDelegate, SELECTION_OUTPUT_TYPES, VALUE_BLOCK_STYLE } from './common';

export namespace SelectionWithTypeBlock {
    export const Type = 'g-selection-with-type';

    export const TYPES = 'types';
    export const INPUT_SELECTION = 'objects';

    export class Delegate implements BlockDelegate {
        inputIsSelection = false;

        constructor(block: Block) {
            block.setOutput(true, SELECTION_OUTPUT_TYPES);

            block.appendValueInput(INPUT_SELECTION).setAlign(inputs.Align.RIGHT).appendField(new FieldLabel('select'));
            block.appendValueInput(TYPES).setAlign(inputs.Align.RIGHT).appendField(new FieldLabel(_withType));

            block.setInputsInline(false);
            block.setStyle(VALUE_BLOCK_STYLE);
            block.setTooltip('');
            block.setHelpUrl('');
        }

        onUpdate(context: BlockContext) {
            const input = context.getInputType(INPUT_SELECTION);
            this.inputIsSelection = input?.type === 'outlet' || input?.type === 'selection';
            if (input && this.inputIsSelection) {
                context.setReturnedType(input);
            }
        }
    }
}

Blocks[SelectionWithTypeBlock.Type] = createDelegate((block) => new SelectionWithTypeBlock.Delegate(block));

luaGenerator.forBlock[SelectionWithTypeBlock.Type] = (block, generator) => {
    let code: string;
    const _block = block as BlockWithDelegate<SelectionWithTypeBlock.Delegate>;
    if (_block.delegate.inputIsSelection) {
        const input = generator.valueToCode(block, SelectionWithTypeBlock.INPUT_SELECTION, Order.ATOMIC);
        const tags = generator.valueToCode(block, SelectionWithTypeBlock.TYPES, Order.ATOMIC);
        code = `${input?.trim()}.${_withType}(${tags?.trim()})`;
    } else {
        code = `{input must be an object selection}`;
    }
    return [code, Order.ATOMIC];
};
