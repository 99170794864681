import { Block, Blocks, FieldLabel, inputs } from 'blockly';
import { Order, luaGenerator } from 'blockly/lua';
import { _withTag, BlockContext } from '@grenton/gm-logic';
import { BlockDelegate, BlockWithDelegate, createDelegate, SELECTION_OUTPUT_TYPES, VALUE_BLOCK_STYLE } from './common';

export namespace SelectionWithTagBlock {
    export const Type = 'g-selection-with-tag';

    export const TAGS = 'tags';
    export const INPUT_SELECTION = 'objects';

    export class Delegate implements BlockDelegate {
        inputIsSelection = false;

        constructor(block: Block) {
            // block.appendDummyInput()
            //     .appendField(new FieldImage("icons/object.svg",16,16))

            block.setOutput(true, SELECTION_OUTPUT_TYPES);

            block.appendValueInput(INPUT_SELECTION).setAlign(inputs.Align.RIGHT).appendField(new FieldLabel('select'));
            // .appendField(new FieldImage("icons/object.svg",16,16))

            block.appendValueInput(TAGS).setAlign(inputs.Align.RIGHT).appendField(new FieldLabel(_withTag));

            block.setInputsInline(false);
            block.setStyle(VALUE_BLOCK_STYLE);
            block.setTooltip('');
            block.setHelpUrl('');
        }

        onUpdate(context: BlockContext) {
            const input = context.getInputType(INPUT_SELECTION);
            this.inputIsSelection = input?.type === 'outlet' || input?.type === 'selection';
            if (input && this.inputIsSelection) {
                context.setReturnedType(input);
            }
        }
    }
}

Blocks[SelectionWithTagBlock.Type] = createDelegate((block) => new SelectionWithTagBlock.Delegate(block));

luaGenerator.forBlock[SelectionWithTagBlock.Type] = (block, generator) => {
    const _block = block as BlockWithDelegate<SelectionWithTagBlock.Delegate>;
    let code: string;
    if (_block.delegate.inputIsSelection) {
        const input = generator.valueToCode(block, SelectionWithTagBlock.INPUT_SELECTION, Order.ATOMIC);
        const tags = generator.valueToCode(block, SelectionWithTagBlock.TAGS, Order.ATOMIC);
        code = `${input?.trim()}.${_withTag}(${tags?.trim()})`;
    } else {
        code = `{input must be an object selection}`;
    }
    return [code, Order.ATOMIC];
};
