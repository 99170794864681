import { Block, Blocks } from 'blockly';
import { luaGenerator, Order } from 'blockly/lua';
import { BlockContext } from '@grenton/gm-logic';
import { BlockDelegate } from './common';

export namespace MethodReturnBlock {
    export const Type = 'g-method-return';

    export class Logic implements BlockDelegate<void> {
        onUpdate(_: BlockContext) {}
    }
}

const DEFAULT_VALUE_INPUT = 'DEFAULT_VALUE';

Blocks[MethodReturnBlock.Type] = {
    init() {
        this.appendValueInput(DEFAULT_VALUE_INPUT).setCheck(null).appendField('return');
        this.setInputsInline(false);
        this.setPreviousStatement(true);
        this.setOutput(false, null);
        this.setColour(230);
        this.setTooltip('');
        this.setHelpUrl('');
    },
};

luaGenerator.forBlock[MethodReturnBlock.Type] = (block: Block, generator) => {
    const defaultValue = generator.valueToCode(block, DEFAULT_VALUE_INPUT, Order.ATOMIC);
    return 'return ' + defaultValue + '\n';
};
