import { RepoClient } from '@grenton/gm-common';

import {
    ComponentDeleteCommandHandler,
    DISCOVERY_EMPTY,
    NetworkView,
    NotificationCentre,
    NotificationCommandHandler,
    NotificationState,
    ProjectImpl,
    networkViewNotifier,
    parseNetworkDef,
    NetworkLookup,
    SelectNetworkLookupHandler,
    NetworkStatusMonitor,
    CertificateStatusMonitor,
    CertificateStatus,
    certificateViewNotifier,
    projectValidator,
    ProjectValidator,
    FirmwareProvider,
    loadNewProject,
    projectImporter,
    SaveAsProjectCommandHandler,
} from '@grenton/gm-logic';
import { ProjectRxDatabaseRepository } from '@grenton/gm/project/store/project-repo';
import log from 'loglevel';

import { combineLatest, distinctUntilChanged, filter, firstValueFrom, from, map } from 'rxjs';
import { HardwareController } from '../hardware/hardware-controller';
import { ProjectHolder } from '@grenton/gm-logic';

import { ClusterClient } from '@grenton/gm-logic';
import { CommandDispatcher } from '@grenton/gm-logic';
import { LinkProjectModuleToHardwareHandler } from '@grenton/gm-logic';
import { StoreHardwareConfigurationHandler } from '@grenton/gm-logic';
import { RemoveCluCommandHandler } from '@grenton/gm-logic';
import { MoveObjectsBetweenModulesCommandHandler } from '@grenton/gm-logic';
import { SwitchEditModeCommandHandler } from '@grenton/gm-logic';
import { AddTagCommandHandler } from '@grenton/gm-logic';
import { EditTagCategoryCommandHandler } from '@grenton/gm-logic';
import { RemoveTagCommandHandler } from '@grenton/gm-logic';
import { AddModuleCommandHandler } from '@grenton/gm-logic';
import { AddVirtualObjectCommandHandler } from '@grenton/gm-logic';
import { ObjectEditCommandHandler } from '@grenton/gm-logic';
import { ProjectExporter } from '@grenton/gm-logic';
import { RemoveTagCategoryCommandHandler } from '@grenton/gm-logic';
import appEnv from '@grenton/gm/app-env';
import { EditProjectSettingsCommandHandler } from '@grenton/gm-logic';
import { I18nLangDeleteCommandHandler, I18nLangAddCommandHandler, I18nLangEditCommandHandler } from '@grenton/gm/settings/i18n';
import { SecurityController, UserEditCommandHandler, UserDeleteCommandHandler } from '@grenton/gm/security';
import { ProjectAutoSave } from '../project/store/project-auto-save';
import { DeleteProjectCommandHandler } from '../project';
import { UserHolder } from '../auth/userholder';
import { TokenHolder } from '../auth/tokenholder';
import { UserAuthenticationCommandHandler, UserTokenCommandHandler } from '../auth/command';
import {
    LocalFabric,
    RemoveProjectModuleLinkToHardwareCommandHandler,
    LoadStoredProjectCommandHandler,
    LoadSampleProjectCommandHandler,
    NewProjectCommandHandler,
    LoadProjectFromJsonCommandHandler,
} from '@grenton/gm-logic';
import { ObjectQuickEditCommandHandler } from '@grenton/gm-logic';
import { EditScriptCommandHandler } from '../editor/backend/edit-script-command';
import { ConfigurationUploader } from '../hardware/configuration-uploader';
import { State, StateUpdater } from '@grenton/gm-logic';
import { CertificateInstallerHandler } from '../hardware/certificate/certificateInstallerHandler';
import { RepoClientImpl } from '@grenton/gm-common/src/repo/repo-client';
import { ScriptEditorController } from '../editor/backend/script-editor-controller';
import { firmwareProviderFromRepo } from '@grenton/gm-logic';
import { createProjectImpl, createProjectOfSingleCLU, importProjectImpl } from '@grenton/gm-logic/src/project/builders';
import { importProjectFromCLU } from '../hardware/importProjectFromCLU';

const networks = parseNetworkDef(appEnv.GRENTON_NETWORKS || 'local=https://grenton{index}.local');

export class Services {
    private _ready = false;

    private _projectRepo?: ProjectRxDatabaseRepository;
    private _editorController?: ScriptEditorController;
    private _firmwareProvider?: FirmwareProvider;
    private _hardwareController?: HardwareController;
    private _projectHolder?: ProjectHolder;
    private _cluProjectMonitor?: ClusterClient;
    private _commandDispatcher?: CommandDispatcher;
    private _projectExporter?: ProjectExporter;
    private _fabric?: LocalFabric;
    private _securityController?: SecurityController;
    private _repoClient?: RepoClientImpl;
    private _configurationUploader?: ConfigurationUploader;
    private _projectValidator?: ProjectValidator;

    readonly userHolder = new UserHolder();
    readonly tokenHolder = new TokenHolder();

    readonly networkViewStore = State<NetworkView>({
        networks,
        discovery: DISCOVERY_EMPTY,
        selectedNetworkId: networks[0]?.id || null,
    });

    private projectStore = State<ProjectImpl | null>(null);
    readonly notificationStore = State<NotificationState>({ notifications: [] });
    private _notificationCentre = new NotificationCentre(this.notificationStore.updater);
    readonly networkStatusStore = State<boolean>(false);
    readonly certificateStatusStore = State<CertificateStatus>('unknown');
    readonly certificateInstallerStore = State<object | null>(null);

    // backward compatibility
    private projectUpdater: StateUpdater<ProjectImpl> = (fn: (project: ProjectImpl) => ProjectImpl | Promise<ProjectImpl>) => {
        return this.projectStore.updater((old) => {
            if (!old) throw new Error('project not loaded');
            return fn(old);
        });
    };

    constructor() {
        new NetworkLookup(this.networkViewStore.updater, this.networkViewStore.provider);
        log.info('beans created');
    }

    get ready() {
        return this._ready;
    }

    private _result: Promise<boolean> | undefined;

    async init() {
        log.setLevel('trace');
        log.info('beans init');

        // double init is related to React.StrictMode
        // todo: does it mean no services will be hot-reloaded after first init?
        if (this._result) {
            return this._result;
        }

        this._repoClient = new RepoClientImpl(appEnv.GRENTON_REPO_URL);
        this._repoClient.startPolling().catch(() => {});

        networkViewNotifier(this.networkViewStore.provider, this.notificationCentre.receiver);
        new NetworkStatusMonitor(appEnv.GRENTON_NETWORK_STATUS_URL, this.networkStatusStore.updater).start();

        const certificateStatusMonitor = new CertificateStatusMonitor(appEnv.GRENTON_CERTIFICATE_VALIDATION_URL, this.certificateStatusStore.updater);
        this.networkStatusStore.provider.pipe(distinctUntilChanged()).subscribe((online) => {
            if (online) {
                certificateStatusMonitor.start();
            } else {
                certificateStatusMonitor.stop();
            }
        });
        certificateViewNotifier(this.certificateStatusStore.provider, this.notificationCentre.receiver);

        this._projectRepo = new ProjectRxDatabaseRepository(this.tokenHolder);
        const servicesThatRequireInit = combineLatest([this._repoClient.loaded, from(this._projectRepo.init())]).pipe(
            map((values) => values.every((value) => value === true)),
            filter((result) => result),
        );

        this._result = firstValueFrom(servicesThatRequireInit).then((_) => {
            this._ready = true;
            log.info('app initialized');

            new ProjectAutoSave(this.projectStore.provider, this.userHolder, this.projectRepo).init();

            return true;
        });

        return this._result;
    }

    private ensureReady() {
        if (!this._ready) {
            throw new Error('beans not ready');
        }
    }

    get notificationCentre() {
        return this._notificationCentre;
    }

    get repoClient(): RepoClient {
        return this._repoClient!;
    }

    get localFabric() {
        if (!this._fabric) {
            this.ensureReady();
            this._fabric = new LocalFabric(this.projectStore.provider, this.projectUpdater);
        }
        return this._fabric;
    }

    get securityController() {
        if (!this._securityController) {
            this.ensureReady();
            this._securityController = new SecurityController(this.projectStore.provider);
        }
        return this._securityController;
    }

    get projectValidator() {
        if (!this._projectValidator) {
            this.ensureReady();
            this._projectValidator = projectValidator(this.firmwareProvider);
        }
        return this._projectValidator;
    }

    get projectExporter() {
        if (!this._projectExporter) {
            this.ensureReady();
            this._projectExporter = new ProjectExporter(this.projectStore.provider, this.projectValidator);
        }
        return this._projectExporter;
    }

    // this is used only by EditorPage
    get editorController() {
        if (!this._editorController) {
            this.ensureReady();
            this._editorController = new ScriptEditorController(this.projectStore.provider, (obj) => this.projectUpdater((p) => p.withObject(obj)));
        }
        return this._editorController;
    }

    get hardwareController() {
        if (!this._hardwareController) {
            this.ensureReady();
            this._hardwareController = new HardwareController(
                this.commandDispatcher,
                this.projectStore.provider,
                projectImporter(this.projectValidator),
                this.networkViewStore.provider,
                this.cluProjectMonitor,
                importProjectFromCLU(
                    this.projectStore.provider,
                    this.projectUpdater,
                    loadNewProject(this.projectStore.updater),
                    importProjectImpl(this.firmwareProvider),
                    createProjectOfSingleCLU(createProjectImpl(this.firmwareProvider)),
                ),
            );
        }
        return this._hardwareController;
    }

    get configurationUploader() {
        if (!this._configurationUploader) {
            this.ensureReady();
            this._configurationUploader = new ConfigurationUploader(this.projectExporter, this.projectUpdater, this.networkViewStore.provider);
        }
        return this._configurationUploader;
    }

    get cluProjectMonitor() {
        if (!this._cluProjectMonitor) {
            this.ensureReady();
            this._cluProjectMonitor = new ClusterClient();
        }
        return this._cluProjectMonitor;
    }

    get projectRepo() {
        if (!this._projectRepo) {
            this.ensureReady();
            this._projectRepo = new ProjectRxDatabaseRepository(this.tokenHolder);
        }
        return this._projectRepo;
    }

    get projectHolder() {
        if (!this._projectHolder) {
            this.ensureReady();
            this._projectHolder = new ProjectHolder(this.projectStore);
        }
        return this._projectHolder;
    }

    get firmwareProvider() {
        if (!this._firmwareProvider) {
            this.ensureReady();
            this._firmwareProvider = firmwareProviderFromRepo(this.repoClient);
        }
        return this._firmwareProvider;
    }

    get commandDispatcher() {
        if (!this._commandDispatcher) {
            this._commandDispatcher = new CommandDispatcher([
                new NotificationCommandHandler(this.notificationCentre.receiver),
                new StoreHardwareConfigurationHandler(this.projectUpdater),
                new LinkProjectModuleToHardwareHandler(this.projectUpdater),
                new RemoveCluCommandHandler(this.projectUpdater),
                new RemoveProjectModuleLinkToHardwareCommandHandler(this.projectUpdater),
                new MoveObjectsBetweenModulesCommandHandler(this.projectUpdater),
                new SwitchEditModeCommandHandler(this.projectUpdater),
                new AddVirtualObjectCommandHandler(this.projectUpdater),
                new AddTagCommandHandler(this.projectUpdater),
                new RemoveTagCategoryCommandHandler(this.projectUpdater),
                new EditTagCategoryCommandHandler(this.projectUpdater),
                new RemoveTagCommandHandler(this.projectUpdater),
                new AddModuleCommandHandler(this.projectUpdater),
                new ObjectEditCommandHandler(this.projectUpdater),
                new ObjectQuickEditCommandHandler(this.projectUpdater),
                new ComponentDeleteCommandHandler(this.projectUpdater),
                new UserEditCommandHandler(this.projectUpdater),
                new UserDeleteCommandHandler(this.projectUpdater),
                new EditProjectSettingsCommandHandler(this.projectUpdater, this.firmwareProvider),
                new I18nLangDeleteCommandHandler(this.projectUpdater),
                new I18nLangAddCommandHandler(this.projectUpdater),
                new I18nLangEditCommandHandler(this.projectUpdater),
                new DeleteProjectCommandHandler(this.projectRepo),
                new SaveAsProjectCommandHandler(this.projectStore.provider, this.projectRepo),
                new EditScriptCommandHandler((ref) => this.editorController.setEditedScript(ref)),
                new UserAuthenticationCommandHandler(this.userHolder),
                new UserTokenCommandHandler(this.tokenHolder),
                new LoadStoredProjectCommandHandler(this.projectRepo, loadNewProject(this.projectStore.updater), importProjectImpl(this.firmwareProvider)),
                new LoadSampleProjectCommandHandler(createProjectImpl(this.firmwareProvider), loadNewProject(this.projectStore.updater)),
                new NewProjectCommandHandler(loadNewProject(this.projectStore.updater), createProjectImpl(this.firmwareProvider)),
                new LoadProjectFromJsonCommandHandler(loadNewProject(this.projectStore.updater), importProjectImpl(this.firmwareProvider)),
                new SelectNetworkLookupHandler(this.networkViewStore.updater),
                new CertificateInstallerHandler(this.certificateInstallerStore.updater),
            ]);
        }
        return this._commandDispatcher;
    }
}
