import { Command, CommandHandler } from '../../dispatcher';
import { StateProvider } from '../../utils';
import { ProjectImpl, ProjectRevisionsImpl } from '../model';
import { ProjectRepository } from '../project-repository';
import { uuid } from '@grenton/gm-common/src';

export class SaveAsProjectCommand implements Command {
    readonly type = 'SaveAsProjectCommand';
    constructor(
        readonly saveAsName: string,
        readonly user: { id: string; email: string },
    ) {}
}

export class SaveAsProjectCommandHandler implements CommandHandler<SaveAsProjectCommand> {
    constructor(
        private projectProvider: StateProvider<ProjectImpl | null>,
        private store: ProjectRepository,
    ) {}
    supports(cmd: Command): boolean {
        return cmd.type === 'SaveAsProjectCommand';
    }
    async execute(cmd: SaveAsProjectCommand): Promise<void> {
        const project = this.projectProvider.value;
        if (!project) throw new Error('project not loaded');

        const copy = project
            .withID(uuid.v4())
            .withLabel(cmd.saveAsName)
            .withNewRevision(ProjectRevisionsImpl.revision(`created as copy of '${project.label}`));

        this.store.store(cmd.user.id, cmd.user.email, copy.export());
    }
}
