import { Block, Blocks, FieldLabel, MenuOption } from 'blockly';
import { Order, luaGenerator } from 'blockly/lua';
import { BlockContext } from '@grenton/gm-logic';
import { BlockDelegate, createDelegate, emptyOption, FieldDropdownModel, STATEMENT_BLOCK_STYLE } from './common';
import { FieldDropdownEx } from './utils/field-dropdown-ex';

export namespace TypeBlock {
    export const Type = 'g-type';

    export const VALUE_FIELD = 'val';
    const VALUE_EMPTY_OPTION = emptyOption('{value}');

    export class Delegate implements BlockDelegate {
        inputIsSelection = false;

        valuesDropdownModel: FieldDropdownModel;
        tree: { [category: string]: string[] } = {};

        constructor(private block: Block) {
            block.setOutput(true, 'string');
            this.valuesDropdownModel = new FieldDropdownModel(block, VALUE_FIELD, [VALUE_EMPTY_OPTION]);

            block.appendDummyInput().appendField(new FieldLabel('type')).appendField(new FieldDropdownEx(this.valuesDropdownModel.generator), VALUE_FIELD);

            block.setInputsInline(false);
            block.setStyle(STATEMENT_BLOCK_STYLE);
            block.setTooltip('');
            block.setHelpUrl('');
        }

        onUpdate(context: BlockContext) {
            const selectedType = this.block.getFieldValue(VALUE_FIELD);
            const types: MenuOption[] = context.project.firmware.deviceTypes.map((cat) => [cat.id, cat.id]);
            types.unshift(VALUE_EMPTY_OPTION);
            this.valuesDropdownModel.setOptions(types, selectedType);
        }
    }
}

Blocks[TypeBlock.Type] = createDelegate((block) => new TypeBlock.Delegate(block));

luaGenerator.forBlock[TypeBlock.Type] = (block, _) => {
    const value = block.getFieldValue(TypeBlock.VALUE_FIELD);
    const code = value ? `"${value}" ` : 'nil';
    return [code, Order.ATOMIC];
};
