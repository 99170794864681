import { OUTLET_OBJECT, ObjectApiImpl, ObjectID } from '@grenton/gm-common';
import { Event, Method } from '@grenton/gm-common/src/model/objects/api';
import { ProjectObjectImpl, ProjectImpl, DEFAULT_EMPTY_SCRIPT, OutletImpl, ScriptImpl } from '@grenton/gm-logic';

type ScriptRef = {
    // path to 'script' within object's api context, e.g.
    // 'outlet1.onClick' or 'method1'

    path: string;

    // in a few places it is necessary to know whether path refers to a method script or event handler

    type: 'method' | 'event';
};

// reference that identifies a single script within a project
export type ObjectScriptRef = {
    objectId: ObjectID;
    scriptRef: ScriptRef;
};

const sameScriptRef = (a: ScriptRef, b: ScriptRef) => {
    return a.type === b.type && a.path === b.path;
};

export const sameObjectScriptRef = (a?: ObjectScriptRef | null, b?: ObjectScriptRef | null) => {
    if (!a && !b) return true;
    if (!a || !b) return false;
    if (a.objectId !== b.objectId) return false;
    return sameScriptRef(a.scriptRef, b.scriptRef);
};

/**
 * ObjectScriptRef serves as a 'pointer',
 * but in editors we need to access tangible data, like script content, and to do so,
 * we need to retrieve ProjectObjectImpl from the project.
 *
 * this object is also passed through a blockly workspace updater.
 *
 * PLAN:
 * on top level we need a "controller" that will receive "ChangeEditingScript" as well as "CommitScriptChanges" commands from editors.
 * it will then create ScriptWithContext and keep it in a context.
 *
 * ChangeEditingScript = {objectId: '...', scriptRef: {path: '...', type: 'method'|'event'}}
 * CommitScriptChanges = {objectScriptRef:'', format: 'visual'|'actions'|'lua', content: '...', commitId:string}
 *
 * commitId will be added to ScriptWithContext
 *
 * editor will ignore ScriptWithContext if commitId is its own, otherwise, it will reset editor to the current content.
 * ---
 * when ActionEditor commits a change, the controller will update visual and lua editors as well. this is a simple approach.
 * more complex would be on-demand refresh, when visual or lua editor is opened, it could "request" such refresh from ScriptWithContext.
 *
 * in that way, all editors stay quite dummy - they edit content internally and do not need to refresh unless there's an external change,
 * they only dispatch "CommitScriptChanges".
 *
 * we can also easily preserve state of editors in the future when switch between top screens.
 */
export class ScriptWithContext {
    static from(project: ProjectImpl, ref: ObjectScriptRef) {
        const entity = project.getObjectById(ref.objectId);
        if (!entity) return null;
        const outlet = findLastOutlet(entity, ref.scriptRef.path);
        return new ScriptWithContext(entity, ref.scriptRef, outlet?.api.api ?? entity.api.api);
    }

    // alternative method if we have just a full path
    static fromPath(project: ProjectImpl, path: string[]) {
        if (path.length < 2) return null;
        const objectId = path[0]!;
        const object = project.getObjectById(objectId);
        if (!object) return null;

        const scriptPath = path.slice(1).join('.');
        const method = object.api.methods[scriptPath];
        if (method) {
            return new ScriptWithContext(object, { path: scriptPath, type: 'method' }, object.api.api);
        } else {
            const outlet = findLastOutlet(object, scriptPath);
            const event = outlet?.api.events[path.at(-1)!];
            return event ? new ScriptWithContext(object, { path: scriptPath, type: 'event' }, outlet.api.api) : null;
        }
    }

    readonly ref: ObjectScriptRef;

    constructor(
        public readonly object: ProjectObjectImpl,
        public readonly scriptRef: ScriptRef,
        public readonly scriptProtocol: ObjectApiImpl,
    ) {
        this.ref = { objectId: object.uuid, scriptRef };
    }

    /**
     * for anonymous controllers it should return object referenced by outlet "object",
     * for regular scriptable - itself.
     */
    getSourceObjectId() {
        return this.object.init.outlets[OUTLET_OBJECT]?.firstStatic || this.object.uuid;
    }

    // main source of script content
    // change of this item should trigger a re-render of editor content (unless this change was caused by an editor in the first place)
    getScript(): ScriptImpl {
        return this.object.scripts.scripts[this.scriptRef.path] || DEFAULT_EMPTY_SCRIPT;
    }

    // used by blockly code generator
    getProtocolItem(): { type: 'method'; method: Method } | { type: 'event'; event: Event } | undefined {
        switch (this.scriptRef.type) {
            case 'method':
                const method = this.scriptProtocol.flat.methods[this.scriptRef.path];
                return method ? { type: 'method', method } : undefined;
            case 'event':
                const event = this.scriptProtocol.flat.events[this.scriptRef.path];
                return event ? { type: 'event', event } : undefined;
        }
    }

    sameScriptInstance(e: ScriptWithContext) {
        return !!e && this.object.uuid === e.object.uuid && sameScriptRef(this.scriptRef, e.scriptRef);
    }
}

/**
 * find the last outlet in the path, e.g.
 * object.buttons.onClick -> return outlet 'buttons'
 * object.methodX -> return outlet 'object'
 * @returns
 */
function findLastOutlet(object: ProjectObjectImpl, scriptPath: string): OutletImpl | undefined {
    const items = scriptPath.split('.');
    let currentApi = object.api;
    let currentOutlet: OutletImpl | undefined;

    for (let i = 0; i < items.length - 1; i++) {
        const item = items[i]!;
        currentOutlet = currentApi.outlets[item];
        if (!currentOutlet) return undefined;
        currentApi = currentOutlet.api;
    }

    return currentOutlet;
}
