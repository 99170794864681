import { Block, Blocks, FieldLabel, MenuOption, inputs } from 'blockly';
import { luaGenerator, Order } from 'blockly/lua';
import { BlockContext } from '@grenton/gm-logic';
import { BlockDelegate, createDelegate, emptyOption, FieldDropdownModel, STATEMENT_BLOCK_STYLE, TYPE_ARRAY } from './common';
import { FieldDropdownEx } from './utils/field-dropdown-ex';

export namespace TagsByCategoryBlock {
    export const Type = 'g-tags-by-category';

    export const TAGS_FIELD = 'tags';
    export const CATEGORY_FIELD = 'category';

    const EMPTY_OPTION = emptyOption('{any}');

    export class Delegate implements BlockDelegate {
        dropdownModel: FieldDropdownModel;

        constructor(private block: Block) {
            this.dropdownModel = new FieldDropdownModel(block, CATEGORY_FIELD, [EMPTY_OPTION]);

            block.appendValueInput(TAGS_FIELD).setAlign(inputs.Align.RIGHT).appendField(new FieldLabel('filter tags'));

            block.appendDummyInput().appendField(new FieldLabel('by category')).appendField(new FieldDropdownEx(this.dropdownModel.generator), CATEGORY_FIELD);

            block.setOutput(true, TYPE_ARRAY);
            block.setInputsInline(false);
            block.setStyle(STATEMENT_BLOCK_STYLE);
            block.setTooltip('');
            block.setHelpUrl('');
        }

        onUpdate(context: BlockContext) {
            const options: MenuOption[] = context.project.tags.categories.map((o) => [o.name, o.name]);
            options.unshift(EMPTY_OPTION);
            this.dropdownModel.setOptions(options, this.block.getFieldValue(CATEGORY_FIELD));
        }
    }
}

Blocks[TagsByCategoryBlock.Type] = createDelegate((block) => new TagsByCategoryBlock.Delegate(block));

luaGenerator.forBlock[TagsByCategoryBlock.Type] = (block, generator) => {
    const input = generator.valueToCode(block, TagsByCategoryBlock.TAGS_FIELD, Order.ATOMIC);
    const cat = block.getFieldValue(TagsByCategoryBlock.CATEGORY_FIELD);
    const code = cat ? `tags(${input?.trim()})._byCategory('${cat}')` : input;
    return [code, Order.ATOMIC];
};
