import { Typography } from "@mui/material";
import { UseTreeItem2Parameters, TreeItem2Provider, TreeItem2Root, TreeItem2Content, TreeItem2IconContainer, TreeItem2Icon, TreeItem2Checkbox, TreeItem2Label, TreeItem2GroupTransition } from "@mui/x-tree-view";
import { useTreeItem2 } from "@mui/x-tree-view/useTreeItem2/useTreeItem2";
import React, { useContext } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { GRichTreeRendererContext } from "./context";
import { GTreeItemCheckboxState, GTreeViewBaseItem } from "./types";

interface GRichTreeItemRenderableProps
    extends Omit<UseTreeItem2Parameters, 'rootRef'>,
    Omit<React.HTMLAttributes<HTMLLIElement>, 'onFocus'> { }


export const GRichTreeItemRenderable = React.forwardRef(function GRichTreeItemRenderable(
    props: GRichTreeItemRenderableProps,
    ref: React.Ref<HTMLLIElement>,
) {
    const { id, itemId, label, disabled, children, ...other } = props;

    const {
        getRootProps,
        getContentProps,
        getIconContainerProps,
        getCheckboxProps,
        getLabelProps,
        getGroupTransitionProps,
        publicAPI,
        status,
    } = useTreeItem2({ id, itemId, children, label, disabled, rootRef: ref });

    const gContext = useContext(GRichTreeRendererContext);    
    const item:GTreeViewBaseItem<{rootClassName?:string}> = publicAPI.getItem(itemId)

    const renderItem = gContext?.renderer ? ()=>{
        const onClick = (e: React.MouseEvent, eventData?:any) => gContext?.onItemClick && gContext.onItemClick(e, item, eventData)
        return React.createElement(gContext!.renderer, {item, onClick})
    } : ()=>(<TreeItem2Label {...getLabelProps()} />)

    const checkboxProps = getCheckboxProps()
    const gCheckboxProps = gContext?.checkboxProps?.[itemId]
    
    return (
        <TreeItem2Provider itemId={itemId}>
            <TreeItem2Root {...getRootProps(other)} className={item.rootClassName}>
                <TreeItem2Content  {...getContentProps()} >
                    <TreeItem2IconContainer {...getIconContainerProps()}>
                        <TreeItem2Icon status={status} />
                    </TreeItem2IconContainer>
                    <TreeItem2Checkbox {...checkboxProps as any} 
                        visible={checkboxProps.visible && gCheckboxProps !== undefined && gCheckboxProps !== GTreeItemCheckboxState.HIDDEN} 
                        disabled={checkboxProps.disabled || gCheckboxProps === GTreeItemCheckboxState.DISABLED} />
                    <ErrorBoundary fallbackRender={TreeItemRendererError}>
                    { renderItem() }
                    </ErrorBoundary>
                </TreeItem2Content>
                {children && <TreeItem2GroupTransition {...getGroupTransitionProps()} />}
            </TreeItem2Root>
        </TreeItem2Provider>
    );
});

function TreeItemRendererError({ error }: { error: Error}) {
    return (
        <Typography variant="s">{error.message}</Typography>
    )
  }