import { ActionCall } from '@grenton/gm-common';
import { ResolvedPath, lua_pathToSelection } from '@grenton/gm-logic';

export function generateCodeForActionMethodCalls(calls: ActionCall[], resolved: ResolvedPath): string {
    const target = resolved.rootObject;
    if (!target) return '-- invalid action (no target object)';

    return calls
        .map((call) => {
            const params = Object.entries(call.params || [])
                .filter((param) => param[1] !== null)
                .map((param) => {
                    return `${param[0]}=${param[1]}`;
                });
            const params_str = params.length ? `{${params.join(', ')}}` : '';
            return `${lua_pathToSelection(resolved)}.${call.callable}(${params_str})`;
        })
        .join('\n');
}
