import { styled, Box } from '@mui/system';

export const Section = styled(Box)(({ theme }) => ({
    width: "100%",
    display: 'flex',
    position: 'relative',
    overflowX: 'scroll',
    flexDirection: 'column',
    padding: theme.spacing(2),
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4)
}));

export const Content = styled(Box)(({  }) => ({
    width: "100%",
    display: 'block',
}));
